.form-fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.mollie-hidden {
  display: none;
}

.display-grid {
  display: grid;
}

.label {
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 600;
}

.form-error,
.field-error {
  margin-top: 8px;
  margin-bottom: 0;
  color: #f00;
  font-size: 13px;
  font-weight: 500;
}

.mollie-component {
  width: 100%;
  padding: 10px 15px;
  color: #222;
  border: 2px solid transparent;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  transition: all 0.05s ease;
}

.mollie-component.has-focus {
  border-color: #07f;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 6px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
}

.mollie-component.is-invalid {
  border-color: #f00;
  background-color: #fff0f0;
}

@media (min-width: 321px) {
  .form-fields {
    grid-template-columns: 1fr 1fr;
  }
  .form-group--card-number {
    grid-column: span 2;
  }
}
.online-payment__input {
  display: none;
}

.online-payment__label {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dedede;
  padding: 1rem !important;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.online-payment__item {
  min-width: 25%;
  box-sizing: border-box;
  padding: 1rem;
}

.online-payment__input:checked ~ .online-payment__label {
  border-color: #21ba45;
  box-shadow: 0 0 0 2px #21ba45 inset;
}

.online-payment__image {
  width: 50px;
  margin-bottom: 0.5rem;
}

.online-payment__credit-card {
  display: none;
}

.online-payment__item--active.online-payment__item--creditcard {
  width: 100%;
}

.online-payment__item--active .online-payment__credit-card {
  display: flex;
  flex-wrap: wrap;
}

.online-online-payment__container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.online-payment__credit-card {
  padding: 1rem;
  border-bottom: 1px solid #d9d9da;
}

#applepay {
  display: none;
}

#mollie_applepay_button {
  display: none;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    width: 100%;
    padding: 19px;
    cursor: pointer;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy;
  }
  .apple-pay-button-with-text > * {
    display: none;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    --apple-pay-scale: 1; /* (height / 32) */
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text > .text {
    font-family: -apple-system;
    font-size: calc(1em * var(--apple-pay-scale));
    font-weight: 300;
    align-self: center;
    margin-right: calc(2px * var(--apple-pay-scale));
  }
  .apple-pay-button-with-text > .logo {
    width: calc(35px * var(--scale));
    height: 100%;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin-left: calc(2px * var(--apple-pay-scale));
    border: none;
  }
}
.draggable {
  position: relative;
}

.mollie-every-label-container span.ui.label {
  margin: 0.5rem 0 0 0.5rem;
}

/* Popup container */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

/* Popup */
.popup {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Popup header */
.popup-header {
  text-align: left;
  margin-bottom: 10px;
}

.popup-header h2 {
  margin: 0;
}

.popup-header hr {
  margin: 5px 0;
  border: none;
  border-top: 1px solid #cccccc;
}

/* Popup content */
.popup-content {
  text-align: center;
  margin-bottom: 20px;
}

.popup-content img {
  display: block;
  margin: 0 auto;
}

/* Popup buttons */
.popup-buttons {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-buttons button {
  margin: 5px;
  padding: 10px 20px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
}

.popup-buttons button:hover {
  background-color: #f0f0f0;
}

/* Styling for cancel button */
.popup-buttons #cancel-button {
  border: none;
}